import firebase from "firebase";


const firebaseConfig = {
    apiKey: "AIzaSyAUoHDbADS6mmuPdLAfvyf1KHBwgsP3df4",
    authDomain: "callpaas-chat.firebaseapp.com",
    projectId: "callpaas-chat",
    storageBucket: "callpaas-chat.appspot.com",
    messagingSenderId: "1044476483806",
    appId: "1:1044476483806:web:095bf8ea2a1e05616f8a4f"
  };

  const firebaseApp = firebase.initializeApp(firebaseConfig);
  const db = firebaseApp.firestore();
  const auth = firebase.auth();
  const provider = new firebase.auth.GoogleAuthProvider();

  export { auth, provider, db };

